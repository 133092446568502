import React from 'react';

function BlogPage() {
    return (
        <div>
            <div className="container has-text-centered">
                Coming soon
            </div>
        </div>
    )
}

export default BlogPage;